import { ProductionPeriodEnum, ProductionPeriodItemInterface, ProductionPeriodItems } from '@/items/ProductionPeriod';
import { ProductionStatusEnum, ProductionStatusItemInterface, ProductionStatusItems } from '@/items/ProductionStatus';
import { Organization, organizationUuid } from '@/models/Organization';
import ErrorHandler from '@/support/ErrorHandler';
import { cloneDeep, isArray, isEqual, isFunction } from 'lodash';
import { DateTime } from 'luxon';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';

@Component<Production>({})
export default class Production extends Vue {
  // #region @Refs

  @Ref()
  protected routerView!: any;

  // #endregion

  // #region Props - Protected

  protected activeOrganization = typeof this.getOrganizationFromRoute(this.$route) !== 'undefined' ? this.getOrganizationFromRoute(this.$route) : organizationUuid.img;

  protected isLoading = true;

  protected isLoadingReports = false;

  protected organizations: Organization[] | null = null;

  protected productionPeriodSelectItems: ProductionPeriodItemInterface[] = (Object.values(ProductionPeriodEnum) as ProductionPeriodEnum[])
    .map((enumValue) => ProductionPeriodItems[enumValue]);

  protected productionStatusSelectItems: ProductionStatusItemInterface[] = (Object.values(ProductionStatusEnum) as ProductionStatusEnum[])
    .map((enumValue) => ProductionStatusItems[enumValue]);

  protected selectedProductionFrom = this.getProductionFromFromRoute(this.$route);

  protected selectedProductionTill = this.getProductionTillFromRoute(this.$route);

  protected selectedProductionStatus: ProductionStatusEnum = this.getProductionStatusFromRoute(this.$route);

  // #endregion

  // #region Props - Plugins

  public $pageTitle = 'Productie';

  // #endregion

  // #region Lifecycle hooks / Init

  protected mounted(): void {
    this.initialize();
  }

  protected beforeDestroy(): void {
    if (isFunction(this.routerView?.resetFilters)) {
      this.routerView.resetFilters();
    }
  }

  protected async initialize(): Promise<void> {
    this.isLoading = true;

    this.initBreadcrumb();

    await this.getOrganizations();

    this.isLoading = false;
  }

  // #endregion

  // #region Handlers
  // #endregion

  // #region Helpers

  protected async getOrganizations(): Promise<void> {
    if (this.$store.state.isServiceOrganization) {
      await this.fetchOrganizations();
    } else {
      this.activeOrganization = this.$store.state.Auth.organization.id;
      this.organizations = [cloneDeep(this.$store.state.Auth.organization)];
    }
  }

  protected getOrganizationFromRoute(route: Route): string {
    if (Array.isArray(route.query.organizations)) {
      return route.query.organizations[0] as string;
    }

    return route.query.organizations;
  }

  protected getProductionFromFromRoute(route: Route): string {
    return route.query['production-from']
      ? route.query['production-from'] as string
      : DateTime.local().toFormat('yyyy-LL-dd');
  }

  protected getProductionTillFromRoute(route: Route): string {
    return route.query['production-till']
      ? route.query['production-till'] as string
      : DateTime.local().toFormat('yyyy-LL-dd');
  }

  protected getProductionStatusFromRoute(route: Route): ProductionStatusEnum {
    return route.query['production-status']
      ? route.query['production-status'] as ProductionStatusEnum
      : ProductionStatusEnum.STANDAARD;
  }

  protected initBreadcrumb(): void {
    this.$root.$emit(
      'breadcrumbUpdated',
      {
        crumb: [
          {
            name: 'Productie',
          },
        ],
      },
    );
  }

  protected async reinitializeFromRoute(route: Route): Promise<void> {
    this.isLoadingReports = true;

    this.activeOrganization = typeof this.getOrganizationFromRoute(route) !== 'undefined' ? this.getOrganizationFromRoute(route) : organizationUuid.img;
    this.selectedProductionFrom = this.getProductionFromFromRoute(route);
    this.selectedProductionTill = this.getProductionTillFromRoute(route);
    this.selectedProductionStatus = this.getProductionStatusFromRoute(route);

    await new Promise((resolve) => setTimeout(resolve, 100)); // Force dalay till rerender

    this.isLoadingReports = false;
  }

  protected replaceRoute(): void {
    const newQuery = {
      ...this.$route.query,
      ...{
        organizations: this.activeOrganization !== organizationUuid.img ? [this.activeOrganization] : [],
        'production-from': this.selectedProductionFrom,
        'production-till': this.selectedProductionTill,
        'production-status': this.selectedProductionStatus,
      },
    };

    this.$router.replace({ path: this.$route.path, query: newQuery }).catch(() => {});
    this.reinitializeFromRoute(this.$route);
  }

  // #endregion

  // #region API requests

  protected async fetchOrganizations(): Promise<void> {
    try {
      this.organizations = await new Organization()
        .sort('created_at', 'ASC')
        .all();

      if (this.organizations?.length && ! this.activeOrganization) {
        this.activeOrganization = this.organizations[0].id || '';
      }
    } catch (error) {
      ErrorHandler.network(error);
    }
  }

  // #endregion

  // #region Getters / Setters

  // #endregion

  // #region @Watchers

  // @Watch('$route', { deep: true })
  // protected routeChanged(newRoute: Route, oldRoute: Route): void {
  //   if (isEqual(newRoute, oldRoute)) { return; }

  //   this.reinitializeFromRoute(newRoute);
  // }

  // #endregion
}
