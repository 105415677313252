var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ACard",
    {
      staticClass: "tw--mt-4",
      attrs: { collapse: "", title: "Productie" },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "AStatefulForm",
                {
                  staticClass:
                    "collapse-inputs tw-relative tw--mt-8 tw-flex tw-flex-row tw-gap-16 tw-items-center"
                },
                [
                  _c("DateFilter", {
                    attrs: { label: "Van" },
                    on: { input: _vm.replaceRoute },
                    model: {
                      value: _vm.selectedProductionFrom,
                      callback: function($$v) {
                        _vm.selectedProductionFrom = $$v
                      },
                      expression: "selectedProductionFrom"
                    }
                  }),
                  _c("DateFilter", {
                    attrs: { label: "Tot" },
                    on: { input: _vm.replaceRoute },
                    model: {
                      value: _vm.selectedProductionTill,
                      callback: function($$v) {
                        _vm.selectedProductionTill = $$v
                      },
                      expression: "selectedProductionTill"
                    }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-tabs",
        {
          staticClass: "tw-bg-background",
          attrs: { "slider-color": "secondary" },
          on: { change: _vm.replaceRoute },
          model: {
            value: _vm.activeOrganization,
            callback: function($$v) {
              _vm.activeOrganization = $$v
            },
            expression: "activeOrganization"
          }
        },
        [
          _vm._l(_vm.organizations, function(organization, index) {
            return [
              _c(
                "v-tab",
                {
                  key: "tab-" + index,
                  staticClass: "tab__filled",
                  class: { active: _vm.activeOrganization === organization.id },
                  attrs: {
                    disabled: _vm.isLoading,
                    href: "#" + organization.id
                  }
                },
                [_vm._v(" " + _vm._s(organization.name) + " ")]
              )
            ]
          })
        ],
        2
      ),
      !_vm.isLoadingReports
        ? _c("router-view", {
            ref: "routerView",
            attrs: { "hide-title": "", useUserFilters: false }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }